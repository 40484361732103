import { marked } from 'marked';
import SanitizedHtml from '~/components/SanitizedHtml';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { UnknownObject } from '~/types';

const renderer = new marked.Renderer();
// Override the link renderer to force links to open a new tab
renderer.link = (href: string, title: string, text: string) => {
  const url = sanitizeUrl(href);
  return `<a href='${url}' title='${title || url}' target='_blank'>${text}</a>`;
};
marked.setOptions({ renderer, breaks: true });

interface IMarkdownProps {
  markdown: string;
  className?: string;
  style?: UnknownObject;
  allowIframe?: boolean;
}

const Markdown = ({
  markdown = '',
  className = '',
  style = {},
  allowIframe = true,
}: IMarkdownProps): JSX.Element => {
  const html = marked(markdown);
  return (
    <SanitizedHtml
      className={`${className} markdown-body`}
      style={style}
      html={html}
      allowIframe={allowIframe}
    />
  );
};

export default Markdown;
